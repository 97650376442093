import React, { useEffect, Fragment } from "react"
import { graphql } from "gatsby"

import "../styles/pages/eye-care-accessories.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GlassesHeading from "../components/GlassesHeading"
import Breadcrumbs from "../components/Breadcrumbs"
import ProductBanner from "../components/ProductBanner"
import FAQBlock from "../components/FAQBlock"
import { CustomList } from "../components/GlassesList"
import { navigateWithFilters } from "../services/utils"
import {accessoryInfo} from "../services/accessoryDictionary"
import { accessoriesFaq } from "../services/data"

const Accessories = ({ data }) => {

  const hubbleAccessories = data.hubbleapi.hubbleAccessoryProducts

  const right_side_text = `We know that eye care is more than just glasses and contact lenses - and at Hubble, we are dedicated to providing you with everything you need to keep your eye health in check. That’s why we apply care and attention in carefully selecting eye care accessories and products that will help keep your eyes, contacts, and glasses in tip-top shape. Make your eye care routine a breeze with our vision care offerings and receive the same world-class customer service you can expect when shopping with us. We’re looking out for you, always.`

  const getBrand = (title) => {
    const accessoryInfoMeta = accessoryInfo.find(obj => {
      return obj.title === title
    })
    return accessoryInfoMeta?.brand
  }


  useEffect(() => {
    if (typeof window !== undefined ) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          items: hubbleAccessories.map(( product, index ) => (

            {
              index: index,
              item_id: product.id,
              item_name: product.title,
              currency: "USD",
              discount: 0,
              item_brand: getBrand(product.title),
              item_category: "accessories",
              item_category2: 'contact accessories',
              item_category3: 'N/A',
              item_category4: 'N/A',
              item_category5: 'N/A',
              item_list_id: `Accessories PCP`,
              price: parseFloat(product.price),
            }
          ))
        }
      })
    }
  }, [hubbleAccessories])

  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/eye-care-accessories",
      "@type": "webpage",
      "name": "Eye Care Accessories for Glasses & Contacts | Hubble Contacts",
      "description": "Get the best in eye care accessories with Hubble. Shop our selection of eye care essentials, from eye drops and wipes to glasses accessories and cases, we have it all in one affordable place.",
      "publisher": {
        "@type": "Organization",
        "name": "Hubble Contacts"
      }
    }
  ]

  const sortedHandles = ["lastacraft-once-daily", "refresh-optive-mega-3-lubricant-eye-ointment", "refresh-digital-pf-preservative-free", "refresh-pm-lubricant-eye-ointment", "refresh-relieva-pf-preservative-free", "refresh-relieva-for-contacts-lubricant-eye-drops", "refresh-revlieva-preservative-free"]
  hubbleAccessories.sort((a, b) => {
    const aSort = sortedHandles.indexOf(a.handle) >= 0 ? sortedHandles.indexOf(a.handle) : 100
    const bSort = sortedHandles.indexOf(b.handle) >= 0 ? sortedHandles.indexOf(b.handle) : 100
    return aSort - bSort;
  });

  return (
    <Layout className='accessories'>
      <SEO  title="Eye Care Accessories for Glasses & Contacts | Hubble Contacts"
            description="Get the best in eye care accessories with Hubble. Shop our selection of eye care essentials, from eye drops and wipes to glasses accessories and cases, we have it all in one affordable place."
            schemaMarkup={schemaMarkup}
            />
      <GlassesHeading
        color="blue"
        title="Eye Care Accessories"
        description="From eye wipes to eye drops to glasses accessories, Hubble’s selection of eye care products
                      has everything you need to help you take care of your Vision health.
                      Shop popular brands including Lumify, Ocuvite and Biotrue."
      />
      <div className="glasses-container">
        <div className="grid-section">
          <Breadcrumbs
            color="blue"
            links={[{ to: "/", label: "Homepage" }, { label: "Accessories" }]}
          />
          <>
            <CustomList type={"accessory"} items={hubbleAccessories} colsNumber={4} />
            <CustomList type={"accessory"} items={hubbleAccessories} colsNumber={3} />
            <CustomList type={"accessory"} items={hubbleAccessories} colsNumber={2} />
            <CustomList type={"accessory"} items={hubbleAccessories} colsNumber={1} />
          </>
          <ProductBanner
            title={"Eye care & glasses accessories we love"}
            right_side_text={right_side_text}
            optional_image="Hubble-Accessories-PCP-Inset-Lifestyle-Image.jpg"
            optional_image_alt_text="Green box of Hubble Contacts on serene bathroom sink"
            color="blue"
          />
        </div>
        <FAQBlock faqList={accessoriesFaq} title="Eye Care Accessories Questions & Answers" />
      </div>
    </Layout>
  )
}

export default Accessories

export const query = graphql`
  query {
    hubbleapi {
      hubbleAccessoryProducts{
        title
        handle
        description
        shopifyProductId
        variantId
        price
        productImage1
        productImage2
        id
        relatedAccessories {
          edges {
            node {
              title
              handle
              description
              shopifyProductId
              variantId
              productImage1
              productImage2
              id
            }
          }
        }
      }
    }
  }
`
